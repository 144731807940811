import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
const CarouselImage1 = require("../../images/Trending Styles/Beach-towel.jpg");
const CarouselImage2 = require("../../images/Trending Styles/turkish-waffle.png");
const CarouselImage3 = require("../../images/Trending Styles/bedspead.png");

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Heading = tw.h2`text-4xl font-bold text-center`;
const ThreeColumn = tw.div`flex flex-wrap -mx-4 justify-center`;
const Column = tw.div`w-full md:w-1/3 px-4 flex flex-col items-center mt-16`;

const Card = styled.div`
  ${tw`w-full bg-white shadow-lg rounded-lg overflow-hidden`}
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.05); // Slightly zoom in
  }
`;
const CardImage = styled.a((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`block h-80 bg-cover bg-center`,
]);

const CardText = tw.div`p-4 text-center`;
const CardTitle = tw.h5`text-xl font-bold`;

const CardAction = styled.a`
  ${tw`mt-4 text-sm font-semibold text-primary-500 border-b-2 border-transparent hover:border-primary-500 transition duration-300`}
`;

export default () => {
  const cards = [
    {
      imageSrc: CarouselImage1,
      title: "Beach Towels",
      linkText: "SEE CATALOG",
      actionLink: "/catalog/beach-towels",
    },
    {
      imageSrc: CarouselImage2,
      title: "Waffle Weave",
      linkText: "SEE CATALOG",
      actionLink: "/catalog/waffle-weave",
    },
    {
      imageSrc: CarouselImage3,
      title: "Bedspreads",
      linkText: "SEE CATALOG",
      actionLink: "/catalog/bed-spreads",
    },
  ];

  return (
    <Container>
      <Content>
        <Heading>Trending Styles</Heading>
        <ThreeColumn>
          {cards.map((card, index) => (
            <Column key={index}>
              <Card>
                <CardImage href={card.actionLink} imageSrc={card.imageSrc} />
                <CardText>
                  <CardTitle>{card.title}</CardTitle>
                  <CardAction href={card.actionLink}>
                    {card.linkText}
                  </CardAction>
                </CardText>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
      </Content>
    </Container>
  );
};
