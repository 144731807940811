import Footer from "components/footers/FiveColumnWithBackground.js";
import Header from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings.js";
import AnimationRevealPage2 from "helpers/AnimationRevealPage2.js";
import { Navigate, useParams } from "react-router-dom";
import tw from "twin.macro";
import Breadcrumb from "./Breadcrumb";
import categories from "./CatalogItems";
import CollectionItem from "./CollectionItem";

const CollectionSection = tw.section`mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mb-16 gap-8`;
const Container = tw.div`mt-20 mx-6`;
const BreadcrumbWrapper = tw.div`mb-4`;

const CatalogPage = () => {
  const { category } = useParams();
  const product = categories.find((product) => product.category === category);

  if (!product) {
    // Redirect to a not found page or home page if category is not valid
    return <Navigate to="/*" />;
  }

  return (
    <>
      <Header />
      <AnimationRevealPage2>
        <Container>
          <SectionHeading>{product.title}</SectionHeading>
          <BreadcrumbWrapper>
            <Breadcrumb></Breadcrumb>
          </BreadcrumbWrapper>
          <CollectionSection>
            {product.items.map((item, index) => (
              <CollectionItem key={index} product={item} />
            ))}
          </CollectionSection>
          <Footer />
        </Container>
      </AnimationRevealPage2>
    </>
  );
};

export default CatalogPage;
