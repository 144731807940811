import React from "react";
import tw from "twin.macro";
import AnimationRevealPage2 from "helpers/AnimationRevealPage2.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import Header from "components/headers/light.js";
import products from "./CatalogItems";
import CollectionItem from "./CollectionItem";
import Breadcrumb from "./Breadcrumb";

// Styling for the collection section and container
const CollectionSection = tw.section`mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mb-16 gap-8`;
const Container = tw.div`mt-20 mx-6`;
const BreadcrumbWrapper = tw.div`mb-4`; // Margin at the bottom to separate it from the grid

export default () => {
  return (
    <>
      <Header />
      <AnimationRevealPage2>
        <Container>
          {/* Breadcrumb placed at the top */}
          <BreadcrumbWrapper>
            <Breadcrumb />
          </BreadcrumbWrapper>

          {/* Grid with collection items */}
          <CollectionSection>
            {products.slice(0, 5).map((product, index) => (
              <CollectionItem key={index} product={product} />
            ))}
          </CollectionSection>

          <Footer />
        </Container>
      </AnimationRevealPage2>
    </>
  );
};
