import React from "react";
import { Link, useLocation } from "react-router-dom";
import tw from "twin.macro";

const BreadcrumbContainer = tw.nav`text-sm mb-4`; // Styling for the breadcrumb container
const BreadcrumbItem = tw.span`text-black`; // Styling for each breadcrumb item

const capitalizeWords = (str) =>
  str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" "); // Capitalize each word

const Breadcrumb = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x); // Filter out empty segments

  return (
    <BreadcrumbContainer aria-label="breadcrumb">
      <BreadcrumbItem>
        <Link to="/">Home</Link> {/* Home link */}
      </BreadcrumbItem>

      {pathnames.map((value, index) => {
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;

        return isLast ? (
          <BreadcrumbItem key={to}>
            {/* Last breadcrumb, non-clickable */}
            &nbsp;&gt;&nbsp;{capitalizeWords(value.replace(/-/g, " "))}
          </BreadcrumbItem>
        ) : (
          <BreadcrumbItem key={to}>
            {/* Other breadcrumb items as clickable links */}
            &nbsp;&gt;&nbsp;
            <Link to={to}>{capitalizeWords(value.replace(/-/g, " "))}</Link>
          </BreadcrumbItem>
        );
      })}
    </BreadcrumbContainer>
  );
};

export default Breadcrumb;
