import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import NotFoundImage from "images/404NotFound.png";
const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>404 Page Not Found!</Subheading>}
        heading="Sorry, we couldn't wrap our heads around this page."
        description="There is no towels or blankets on this page - nothing to see here!"
        buttonRounded={false}
        primaryButtonText="Home"
        primaryButtonUrl="/"
        imageSrc={NotFoundImage}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
