import React from "react";
import tw from "twin.macro";
import Header from "components/headers/light.js";

const CollectionItemContainer = tw.div`text-center`;
const CollectionImageContainer = tw.a`block`;
const CollectionImageWrapper = tw.div`relative w-full aspect-square overflow-hidden`;
const CollectionImage = tw.img`absolute inset-0 w-full h-full object-cover transition-transform duration-500 ease-in-out transform hover:scale-110`;
const CollectionTitle = tw.h3`mt-4 text-lg font-semibold`;

const CollectionItem = ({ product }) => {
  return (
    <CollectionItemContainer>
      <CollectionImageContainer href={product.url}>
        <CollectionImageWrapper>
          <CollectionImage src={product.imageSrc} alt={product.title} />
        </CollectionImageWrapper>
      </CollectionImageContainer>
      <CollectionTitle>{product.title}</CollectionTitle>
    </CollectionItemContainer>
  );
};

export default CollectionItem;
