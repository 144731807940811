import React, { useState, useRef } from "react";
import tw from "twin.macro";
import { useParams, Navigate } from "react-router-dom";
import AnimationRevealPage2 from "helpers/AnimationRevealPage2.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import Header from "components/headers/light.js";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import styled from "styled-components";
import products from "./ProductItems";
import Breadcrumb from "./Breadcrumb";

const Content = tw.div`max-w-screen-xl mt-10 mx-auto py-10 md:py-12 lg:py-16 px-2 sm:px-4 md:px-6 lg:px-8 flex-col`;

const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0 px-2 sm:px-4 md:px-6 lg:px-8`;

const Row = tw.div`flex flex-row justify-start w-full`;

const MainImageContainer = tw.div`
  relative w-full max-w-xl mx-auto aspect-square
  sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl
  mb-2
`;

const ProductInfoContainer = tw.div`
  relative w-full max-w-xl mx-auto aspect-square
  sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl
  
`;

const MainImage = styled.img`
  ${tw`w-full h-full object-cover`}
`;

const ChevronButton = styled.button`
  ${tw`absolute top-1/2 transform -translate-y-1/2 p-2 rounded-full text-black`};
  z-index: 10;
  background-color: rgba(235, 235, 235, 0.4);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(66, 153, 225, 0.6);
    color: white;
  }
`;

const PrevButton = styled(ChevronButton)`
  ${tw`left-0 ml-4`};
`;

const NextButton = styled(ChevronButton)`
  ${tw`right-0 mr-4`};
`;

const PhotoGrid = styled.div`
  ${tw`flex justify-center mt-6 flex-wrap`};
  width: 100%;
  max-width: 600px;
  gap: 8px;
  margin: 0 2px;

  @media (min-width: 640px) {
    margin: 0 4px; // sm
  }
  @media (min-width: 768px) {
    margin: 0 6px; // md
  }
  @media (min-width: 1024px) {
    margin: 0 8px; // lg
  }
`;

const Thumbnail = styled.img`
  ${tw`cursor-pointer rounded-lg`};
  width: ${({ imageCount }) => (imageCount <= 5 ? "20%" : "calc(100% / 6)")};
  height: auto; // This will be set to a fixed height to maintain the aspect ratio
  aspect-ratio: 1 / 1; // Ensures the aspect ratio is 1:1
  object-fit: cover; // Ensures the image covers the container without distortion
  opacity: ${({ active }) => (active ? 1 : 0.6)};
  border: ${({ active }) => (active ? "2px solid #5a67d8" : "none")};

  &:hover {
    opacity: 1;
  }
`;

const ProductTitle = tw.h1`
  text-lg sm:text-lg md:text-xl lg:text-2xl xl:text-3xl
  font-bold  mb-4
`;

const ProductInfo = tw.div`
  text-sm sm:text-sm md:text-sm lg:text-lg xl:text-xl
  text-gray-700 mt-4 
`;

const ProductPage = () => {
  const { category, product: productParam } = useParams();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const sliderRef = useRef(null);

  const product = products.find(
    (item) => item.category === category && item.url === productParam
  );

  if (!product) {
    return <Navigate to="/*" />;
  }

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? product.images.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === product.images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <>
      <Header />
      <AnimationRevealPage2>
        <Content>
          <Row>
            <Column>
              <Breadcrumb />
            </Column>
          </Row>
          <Row>
            <Column>
              <MainImageContainer>
                <PrevButton onClick={handlePrevImage}>
                  <ChevronLeftIcon />
                </PrevButton>
                <MainImage
                  src={product.images[currentImageIndex]}
                  alt={`Product Image ${currentImageIndex + 1}`}
                />
                <NextButton onClick={handleNextImage}>
                  <ChevronRightIcon />
                </NextButton>
              </MainImageContainer>
              <PhotoGrid>
                {product.images.map((image, index) => (
                  <Thumbnail
                    key={index}
                    src={image}
                    alt={`Thumbnail ${index + 1}`}
                    active={currentImageIndex === index}
                    onClick={() => setCurrentImageIndex(index)}
                  />
                ))}
              </PhotoGrid>
            </Column>
            <Column>
              <ProductInfoContainer>
                <ProductTitle>{product.title}</ProductTitle>
                <ProductInfo>
                  <div>Fabric Type: {product.fabricType}</div>
                  <div>Size: {product.size}</div>
                  <div>Weight: {product.weight}</div>
                </ProductInfo>
              </ProductInfoContainer>
            </Column>
          </Row>
        </Content>
        <Footer />
      </AnimationRevealPage2>
    </>
  );
};

export default ProductPage;
