import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import tw from "twin.macro";
import AnimationRevealPage2 from "helpers/AnimationRevealPage2.js";
import Features from "components/features/ThreeColWithSideImage.js";
import FAQ from "components/faqs/SingleCol.js";
import Footer from "components/footers/FiveColumnWithBackground.js";

import Header from "components/headers/light.js";

import StarIconImage from "images/value.svg";
import PatternIconImage from "images/pattern.svg";
import SustainableIconImage from "images/sustainable.svg";

import Carousel from "components/cards/Carousel";
import ThreeCards from "components/cards/ThreeCards";
import ThreeColSlider2 from "components/cards/ThreeColSlider-2";

const SliderImage11 = require("../images/Popular Designs/Blue-Eye.jpg");
const SliderImage12 = require("../images/Popular Designs/Blue-Eye-2.jpg");
const SliderImage21 = require("../images/Popular Designs/Floral.jpg");
const SliderImage22 = require("../images/Popular Designs/Floral-2.jpg");
const SliderImage31 = require("../images/Popular Designs/Hotel-Baja.jpg");
const SliderImage32 = require("../images/Popular Designs/Hotel-Baja-2.jpg");
const SliderImage41 = require("../images/Popular Designs/Suns-Out.jpg");
const SliderImage42 = require("../images/Popular Designs/Suns-Out-2.jpg");
const SliderImage51 = require("../images/Popular Designs/Soho-Paradise.jpg");
const SliderImage52 = require("../images/Popular Designs/Soho-Paradise-2.jpg");
const SliderImage61 = require("../images/Popular Designs/Santorini.jpg");
const SliderImage62 = require("../images/Popular Designs/Santorini-2.jpg");

export default function HomePage() {
  const { hash } = useLocation();
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  return (
    <>
      <Header></Header>
      <AnimationRevealPage2>
        <Carousel />
        <ThreeCards></ThreeCards>
        <Features
          cards={[
            {
              imageSrc: StarIconImage,
              title: "High Quality",
            },
            {
              imageSrc: PatternIconImage,
              title: "Unique Design",
            },
            {
              imageSrc: SustainableIconImage,
              title: "Eco-Friendly",
            },
          ]}
        />
        <ThreeColSlider2
          heading="Popular Designs"
          cards={[
            {
              imageSrc: SliderImage41,
              secondImageSrc: SliderImage42,
              smallText: "Suns Out",
              url: "/catalog/classic-peshtemals/suns-out",
            },
            {
              imageSrc: SliderImage11,
              secondImageSrc: SliderImage12,
              smallText: "Blue Eye",
              url: "/catalog/classic-peshtemals/blue-eye",
            },
            {
              imageSrc: SliderImage51,
              secondImageSrc: SliderImage52,
              smallText: "Soho Paradise",
              url: "/catalog/classic-peshtemals/soho-paradise",
            },
            {
              imageSrc: SliderImage31,
              secondImageSrc: SliderImage32,
              smallText: "Hotel Baja",
              url: "/catalog/classic-peshtemals/hotel-baja",
            },
            {
              imageSrc: SliderImage61,
              secondImageSrc: SliderImage62,
              smallText: "Santorini",
              url: "/catalog/classic-peshtemals/santorini",
            },
          ]}
        ></ThreeColSlider2>

        <FAQ
          id="about-us"
          subheading={""}
          heading={
            <>
              About <HighlightedText>Wave & Shine</HighlightedText>
            </>
          }
          description={
            "Bring the serene beauty of the coast into your home with Wave and Shine's luxurious bath and bedding textiles. Our beach towels and linens transform every moment into a breezy, seaside escape."
          }
          faqs={[
            {
              question: "What is the origin of your products?",
              answer:
                "Our products are crafted in Denizli, Turkey, renowned as the textile capital.",
            },
            {
              question: "Why would I chose Wave and Shine towels and beddings?",
              answer:
                "We stand by our products, offering superior quality in every type of towel and bedding fabric, delivered promptly at competitive prices.",
            },
            {
              question: "What is unique about you?",
              answer:
                "Wave and Shine was founded and is operated by two young entrepreneurs who are passionate about designing and delivering high-quality textile products. Our dynamic, customer-centric approach sets us apart as the premier wholesaler of towels and bedding in the market.",
            },
          ]}
        />
        <Footer />
      </AnimationRevealPage2>
    </>
  );
}
