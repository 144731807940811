const ClassicPeshtemals = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/Classic Peshtemal Stone Washed 25.6.jpg");

const BlackStripe1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/black-stipe/1.jpg");
const BlackStripe2 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/black-stipe/2.jpg");
const BlackStripe3 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/black-stipe/3.jpg");

const Blueeye1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/blue-eye/(1).jpg");
const Blueeye2 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/blue-eye/(2).jpg");
const Blueeye3 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/blue-eye/(3).jpg");

const Cherry1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/cherry/(1).jpg");
const Cherry2 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/cherry/(2).jpg");
const Cherry3 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/cherry/(3).jpg");

const ClassicStriped1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/classic-striped/(1).jpg");
const ClassicStriped2 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/classic-striped/(2).jpg");
const ClassicStriped3 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/classic-striped/(3).jpg");
const ClassicStriped4 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/classic-striped/(4).jpg");
const ClassicStriped5 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/classic-striped/(5).jpg");
const ClassicStriped6 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/classic-striped/(6).jpg");
const ClassicStriped7 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/classic-striped/(7).jpg");

const GreenOlive1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/green-olive/(1).jpg");
const GreenOlive2 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/green-olive/(2).jpg");
const GreenOlive3 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/green-olive/(3).jpg");

const HotelBaja1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/hotel-baja/(1).jpg");
const HotelBaja2 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/hotel-baja/(2).jpg");
const HotelBaja3 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/hotel-baja/(3).jpg");

const Lailac1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/lailac/(1).jpg");
const Lailac2 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/lailac/(2).jpg");
const Lailac3 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/lailac/(3).jpg");

const Navy1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/navy/(1).jpg");
const Navy2 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/navy/(2).jpg");
const Navy3 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/navy/(3).jpg");

const Noir1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/noir/(1).jpg");
const Noir2 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/noir/(2).jpg");
const Noir3 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/noir/(3).jpg");
const Noir4 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/noir/(4).jpg");

const Oriental1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/oriental/(1).jpg");
const Oriental2 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/oriental/(2).jpg");
const Oriental3 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/oriental/(3).jpg");
const Oriental4 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/oriental/(4).jpg");
const Oriental5 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/oriental/(5).jpg");

const SunsOut1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/suns-out/(1).jpg");
const SunsOut2 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/suns-out/(2).jpg");
const SunsOut3 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/suns-out/(3).jpg");

const Santorini1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/santorini/(1).jpg");
const Santorini2 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/santorini/(2).jpg");
const Santorini3 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/santorini/(3).jpg");

const Tiramisu1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/tiramisu/(1).jpg");
const Tiramisu2 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/tiramisu/(2).jpg");
const Tiramisu3 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/tiramisu/(3).jpg");
const Tiramisu4 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/tiramisu/(4).jpg");

const Sand1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/sand/(1).jpg");
const Sand2 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/sand/(2).jpg");
const Sand3 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/sand/(3).jpg");

const SohoParadise1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/soho-paradise/(1).jpg");
const SohoParadise2 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/soho-paradise/(2).jpg");
const SohoParadise3 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/soho-paradise/(3).jpg");

const Pink1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/pink/(1).jpg");
const Pink2 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/pink/(2).jpg");
const Pink3 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/pink/(3).jpg");

const MuslinPeshtemals11 = require("../images/catalog_item_imgs/peshtemal_towels/muslin_peshtemals/4_layer_muslin (2).jpg");
const MuslinPeshtemals12 = require("../images/catalog_item_imgs/peshtemal_towels/muslin_peshtemals/4_layer_muslin (3).jpg");
const MuslinPeshtemals13 = require("../images/catalog_item_imgs/peshtemal_towels/muslin_peshtemals/4_layer_muslin (4).jpg");
const MuslinPeshtemals14 = require("../images/catalog_item_imgs/peshtemal_towels/muslin_peshtemals/4_layer_muslin (5).jpg");
const MuslinPeshtemals15 = require("../images/catalog_item_imgs/peshtemal_towels/muslin_peshtemals/4_layer_muslin (6).jpg");

const ClassicBathRobes1 = require("../images/catalog_item_imgs/bathrobes/classic_robes/1.jpg");
const ClassicBathRobes2 = require("../images/catalog_item_imgs/bathrobes/classic_robes/2.jpg");
const ClassicBathRobes3 = require("../images/catalog_item_imgs/bathrobes/classic_robes/3.jpg");
const ClassicBathRobes4 = require("../images/catalog_item_imgs/bathrobes/classic_robes/4.jpg");
const ClassicBathRobes5 = require("../images/catalog_item_imgs/bathrobes/classic_robes/5.jpg");

const MuslinBathrobes1 = require("../images/catalog_item_imgs/bathrobes/muslin_bathrobes/regular_muslin_bathrobes/1.jpg");
const MuslinBathrobes2 = require("../images/catalog_item_imgs/bathrobes/muslin_bathrobes/regular_muslin_bathrobes/2.jpg");
const MuslinBathrobes3 = require("../images/catalog_item_imgs/bathrobes/muslin_bathrobes/regular_muslin_bathrobes/3.jpg");
const MuslinBathrobes4 = require("../images/catalog_item_imgs/bathrobes/muslin_bathrobes/regular_muslin_bathrobes/4.jpg");
const MuslinBathrobes5 = require("../images/catalog_item_imgs/bathrobes/muslin_bathrobes/regular_muslin_bathrobes/5.webp");

const FourLMuslinBathrobes1 = require("../images/catalog_item_imgs/bathrobes/muslin_bathrobes/four-layer-muslin-bathrobes/1.jpg");
const FourLMuslinBathrobes2 = require("../images/catalog_item_imgs/bathrobes/muslin_bathrobes/four-layer-muslin-bathrobes/2.webp");
const FourLMuslinBathrobes3 = require("../images/catalog_item_imgs/bathrobes/muslin_bathrobes/four-layer-muslin-bathrobes/3.webp");
const FourLMuslinBathrobes4 = require("../images/catalog_item_imgs/bathrobes/muslin_bathrobes/four-layer-muslin-bathrobes/2.jpg");
const FourLMuslinBathrobes5 = require("../images/catalog_item_imgs/bathrobes/muslin_bathrobes/four-layer-muslin-bathrobes/3.jpg");
const FourLMuslinBathrobes6 = require("../images/catalog_item_imgs/bathrobes/muslin_bathrobes/four-layer-muslin-bathrobes/5.webp");

const MultiColorBackpack1 = require("../images/catalog_item_imgs/peshtemal_backpacks/multi_color/(1).webp");
const MultiColorBackpack2 = require("../images/catalog_item_imgs/peshtemal_backpacks/multi_color/(2).webp");
const MultiColorBackpack3 = require("../images/catalog_item_imgs/peshtemal_backpacks/multi_color/(3).webp");
const MultiColorBackpack4 = require("../images/catalog_item_imgs/peshtemal_backpacks/multi_color/(4).webp");
const MultiColorBackpack5 = require("../images/catalog_item_imgs/peshtemal_backpacks/multi_color/(5).webp");
const MultiColorBackpack6 = require("../images/catalog_item_imgs/peshtemal_backpacks/multi_color/(6).webp");

const RainbowBackpack1 = require("../images/catalog_item_imgs/peshtemal_backpacks/rainbow/1.png");
const RainbowBackpack2 = require("../images/catalog_item_imgs/peshtemal_backpacks/rainbow/2.png");
const RainbowBackpack3 = require("../images/catalog_item_imgs/peshtemal_backpacks/rainbow/3.png");
const RainbowBackpack4 = require("../images/catalog_item_imgs/peshtemal_backpacks/rainbow/4.png");
const RainbowBackpack5 = require("../images/catalog_item_imgs/peshtemal_backpacks/rainbow/5.png");

const StripedBackpack1 = require("../images/catalog_item_imgs/peshtemal_backpacks/striped/(1).webp");
const StripedBackpack2 = require("../images/catalog_item_imgs/peshtemal_backpacks/striped/(2).webp");
const StripedBackpack3 = require("../images/catalog_item_imgs/peshtemal_backpacks/striped/(3).webp");
const StripedBackpack4 = require("../images/catalog_item_imgs/peshtemal_backpacks/striped/(4).webp");
const StripedBackpack5 = require("../images/catalog_item_imgs/peshtemal_backpacks/striped/(5).webp");
const StripedBackpack6 = require("../images/catalog_item_imgs/peshtemal_backpacks/striped/(6).webp");
const StripedBackpack7 = require("../images/catalog_item_imgs/peshtemal_backpacks/striped/(7).webp");

const DiamondThrow1 = require("../images/catalog_item_imgs/bedspreads_throws/Jacq_Bed_Throws/diamond/1.webp");
const DiamondThrow2 = require("../images/catalog_item_imgs/bedspreads_throws/Jacq_Bed_Throws/diamond/1.jpg");
const DiamondThrow3 = require("../images/catalog_item_imgs/bedspreads_throws/Jacq_Bed_Throws/diamond/2.webp");
const DiamondThrow4 = require("../images/catalog_item_imgs/bedspreads_throws/Jacq_Bed_Throws/diamond/3.jpg");
const DiamondThrow5 = require("../images/catalog_item_imgs/bedspreads_throws/Jacq_Bed_Throws/diamond/3.webp");

const StripedThrow1 = require("../images/catalog_item_imgs/bedspreads_throws/Jacq_Bed_Throws/striped/1 (1).jpg");
const StripedThrow2 = require("../images/catalog_item_imgs/bedspreads_throws/Jacq_Bed_Throws/striped/1 (2).jpg");
const StripedThrow3 = require("../images/catalog_item_imgs/bedspreads_throws/Jacq_Bed_Throws/striped/1 (3).webp");
const StripedThrow4 = require("../images/catalog_item_imgs/bedspreads_throws/Jacq_Bed_Throws/striped/2 (1).webp");
const StripedThrow5 = require("../images/catalog_item_imgs/bedspreads_throws/Jacq_Bed_Throws/striped/2 (2).webp");
const StripedThrow6 = require("../images/catalog_item_imgs/bedspreads_throws/Jacq_Bed_Throws/striped/2 (3).webp");

const MuslinBedspread1 = require("../images/catalog_item_imgs/bedspreads_throws/Muslin_Bed_Spreads/1.webp");
const MuslinBedspread2 = require("../images/catalog_item_imgs/bedspreads_throws/Muslin_Bed_Spreads/2.webp");
const MuslinBedspread3 = require("../images/catalog_item_imgs/bedspreads_throws/Muslin_Bed_Spreads/3.webp");
const MuslinBedspread4 = require("../images/catalog_item_imgs/bedspreads_throws/Muslin_Bed_Spreads/4.webp");
const MuslinBedspread5 = require("../images/catalog_item_imgs/bedspreads_throws/Muslin_Bed_Spreads/5.webp");
const MuslinBedspread6 = require("../images/catalog_item_imgs/bedspreads_throws/Muslin_Bed_Spreads/6.webp");
const MuslinBedspread7 = require("../images/catalog_item_imgs/bedspreads_throws/Muslin_Bed_Spreads/7.webp");
const MuslinBedspread8 = require("../images/catalog_item_imgs/bedspreads_throws/Muslin_Bed_Spreads/all-colors.webp");

const MuslinThrow1 = require("../images/catalog_item_imgs/bedspreads_throws/Muslin_Throws/ (1).webp");
const MuslinThrow2 = require("../images/catalog_item_imgs/bedspreads_throws/Muslin_Throws/ (2).webp");
const MuslinThrow3 = require("../images/catalog_item_imgs/bedspreads_throws/Muslin_Throws/ (3).webp");
const MuslinThrow4 = require("../images/catalog_item_imgs/bedspreads_throws/Muslin_Throws/ (4).webp");
const MuslinThrow5 = require("../images/catalog_item_imgs/bedspreads_throws/Muslin_Throws/ (5).webp");
const MuslinThrow6 = require("../images/catalog_item_imgs/bedspreads_throws/Muslin_Throws/ (6).webp");
const MuslinThrow7 = require("../images/catalog_item_imgs/bedspreads_throws/Muslin_Throws/6.webp");

const WaffleHandTowel1 = require("../images/catalog_item_imgs/waffle/waffle_hand_towels/1.jpg");
const WaffleHandTowel2 = require("../images/catalog_item_imgs/waffle/waffle_hand_towels/2.jpg");
const WaffleHandTowel3 = require("../images/catalog_item_imgs/waffle/waffle_hand_towels/3.jpg");
const WaffleHandTowel4 = require("../images/catalog_item_imgs/waffle/waffle_hand_towels/4.jpg");
const WaffleHandTowel5 = require("../images/catalog_item_imgs/waffle/waffle_hand_towels/5.jpg");
const WaffleHandTowel6 = require("../images/catalog_item_imgs/waffle/waffle_hand_towels/6.jpg");

const WaffleBedspread1 = require("../images/catalog_item_imgs/waffle/waffle_bedspreads/1.jpg");
const WaffleBedspread2 = require("../images/catalog_item_imgs/waffle/waffle_bedspreads/2.jpg");
const WaffleBedspread3 = require("../images/catalog_item_imgs/waffle/waffle_bedspreads/3.jpg");
const WaffleBedspread4 = require("../images/catalog_item_imgs/waffle/waffle_bedspreads/4.jpg");
const WaffleBedspread5 = require("../images/catalog_item_imgs/waffle/waffle_bedspreads/5.webp");
const WaffleBedspread6 = require("../images/catalog_item_imgs/waffle/waffle_bedspreads/6.jpg");
const WaffleBedspread7 = require("../images/catalog_item_imgs/waffle/waffle_bedspreads/7.jpg");
const WaffleBedspread8 = require("../images/catalog_item_imgs/waffle/waffle_bedspreads/8.jpg");
const WaffleBedspread9 = require("../images/catalog_item_imgs/waffle/waffle_bedspreads/9.jpg");

const WaffleBathrobe1 = require("../images/catalog_item_imgs/waffle/waffle_bathrobes/adult_waf_robe/1.jpg");
const WaffleBathrobe2 = require("../images/catalog_item_imgs/waffle/waffle_bathrobes/adult_waf_robe/2.jpg");
const WaffleBathrobe3 = require("../images/catalog_item_imgs/waffle/waffle_bathrobes/adult_waf_robe/3.jpg");
const WaffleBathrobe4 = require("../images/catalog_item_imgs/waffle/waffle_bathrobes/adult_waf_robe/4.jpg");
const WaffleBathrobe5 = require("../images/catalog_item_imgs/waffle/waffle_bathrobes/adult_waf_robe/2.webp");
const WaffleBathrobe6 = require("../images/catalog_item_imgs/waffle/waffle_bathrobes/adult_waf_robe/4.webp");

const WafflePeshtemal1 = require("../images/catalog_item_imgs/waffle/waffle_peshtemal/1.jpg");
const WafflePeshtemal2 = require("../images/catalog_item_imgs/waffle/waffle_peshtemal/2.jpg");
const WafflePeshtemal3 = require("../images/catalog_item_imgs/waffle/waffle_peshtemal/3.jpg");
const WafflePeshtemal4 = require("../images/catalog_item_imgs/waffle/waffle_peshtemal/4.jpg");
const WafflePeshtemal5 = require("../images/catalog_item_imgs/waffle/waffle_peshtemal/5.jpg");
const WafflePeshtemal6 = require("../images/catalog_item_imgs/waffle/waffle_peshtemal/6.jpg");

const products = [
  {
    images: [
      ClassicBathRobes1,
      ClassicBathRobes2,
      ClassicBathRobes3,
      ClassicBathRobes4,
      ClassicBathRobes5,
    ],
    url: "classic-bathrobes",
    category: "bathrobes",
    title: "Classic Bathrobes",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
  {
    images: [
      MuslinBathrobes1,
      MuslinBathrobes2,
      MuslinBathrobes3,
      MuslinBathrobes5,
    ],
    url: "regular-muslin-robes",
    category: "muslin-bathrobes",
    title: "Regular Muslin Bathrobes",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
  {
    images: [
      FourLMuslinBathrobes1,
      FourLMuslinBathrobes2,
      FourLMuslinBathrobes3,
      FourLMuslinBathrobes4,
      FourLMuslinBathrobes5,
      FourLMuslinBathrobes6,
    ],
    url: "four-layer-muslin-robes",
    category: "muslin-bathrobes",
    title: "Four Layer Muslin Bathrobes",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
  {
    images: [BlackStripe1, BlackStripe2, BlackStripe3],
    url: "black-stripe",
    category: "classic-peshtemals",
    title: "Black Stripe",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
  {
    images: [Oriental1, Oriental2, Oriental3, Oriental4, Oriental5],
    url: "oriental",
    category: "classic-peshtemals",
    title: "Oriental",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
  {
    images: [SunsOut1, SunsOut2, SunsOut3],
    url: "suns-out",
    category: "classic-peshtemals",
    title: "Suns Out",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
  {
    images: [Santorini1, Santorini2, Santorini3],
    url: "santorini",
    category: "classic-peshtemals",
    title: "Santorini",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
  {
    images: [Tiramisu1, Tiramisu2, Tiramisu3, Tiramisu4],
    url: "tiramisu",
    category: "classic-peshtemals",
    title: "Tiramisu",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
  {
    images: [Lailac1, Lailac2, Lailac3],
    url: "lailac",
    category: "classic-peshtemals",
    title: "Lailac",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
  {
    images: [Blueeye1, Blueeye2, Blueeye3],
    url: "blue-eye",
    category: "classic-peshtemals",
    title: "Blue Eye",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
  {
    images: [Noir1, Noir2, Noir3, Noir4],
    url: "noir",
    category: "classic-peshtemals",
    title: "Noir",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
  {
    images: [GreenOlive1, GreenOlive2, GreenOlive3],
    url: "green-olive",
    category: "classic-peshtemals",
    title: "Green Olive",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
  {
    images: [Sand1, Sand2, Sand3],
    url: "sand",
    category: "classic-peshtemals",
    title: "Sand",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
  {
    images: [Cherry1, Cherry2, Cherry3],
    url: "cherry",
    category: "classic-peshtemals",
    title: "Cherry",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
  {
    images: [SohoParadise1, SohoParadise2, SohoParadise3],
    url: "soho-paradise",
    category: "classic-peshtemals",
    title: "Soho Paradise",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
  {
    images: [Pink1, Pink2, Pink3],
    url: "pink",
    category: "classic-peshtemals",
    title: "Pink",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
  {
    images: [
      ClassicStriped1,
      ClassicStriped2,
      ClassicStriped3,
      ClassicStriped4,
      ClassicStriped5,
      ClassicStriped6,
      ClassicStriped7,
    ],
    url: "classic-striped",
    category: "classic-peshtemals",
    title: "Classic Striped",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
  {
    images: [HotelBaja1, HotelBaja2, HotelBaja3],
    url: "hotel-baja",
    category: "classic-peshtemals",
    title: "Hotel Baja",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
  {
    images: [Navy1, Navy2, Navy3],
    url: "navy",
    category: "classic-peshtemals",
    title: "Navy",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
  {
    images: [
      MuslinPeshtemals11,
      MuslinPeshtemals12,
      MuslinPeshtemals13,
      MuslinPeshtemals14,
      MuslinPeshtemals15,
    ],
    url: "four-layer-muslin",
    category: "muslin-peshtemals",
    title: "4 Layer Muslin Peshtemal",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
  {
    images: [
      MultiColorBackpack1,
      MultiColorBackpack2,
      MultiColorBackpack3,
      MultiColorBackpack4,
      MultiColorBackpack5,
      MultiColorBackpack6,
    ],
    url: "multi-color",
    category: "peshtemal-backpacks",
    title: "Multi Color",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
  {
    images: [
      RainbowBackpack1,
      RainbowBackpack2,
      RainbowBackpack3,
      RainbowBackpack4,
      RainbowBackpack5,
    ],
    url: "rainbow",
    category: "peshtemal-backpacks",
    title: "Rainbow",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
  {
    images: [
      StripedBackpack1,
      StripedBackpack2,
      StripedBackpack3,
      StripedBackpack4,
      StripedBackpack5,
      StripedBackpack6,
      StripedBackpack7,
    ],
    url: "striped",
    category: "peshtemal-backpacks",
    title: "Striped",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
  {
    images: [
      WaffleHandTowel1,
      WaffleHandTowel2,
      WaffleHandTowel3,
      WaffleHandTowel4,
      WaffleHandTowel5,
      WaffleHandTowel6,
    ],
    url: "waffle-hand-towels",
    category: "waffle-weave",
    title: "Waffle Hand Towels",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
  {
    images: [
      WaffleBedspread1,
      WaffleBedspread2,
      WaffleBedspread3,
      WaffleBedspread4,
      WaffleBedspread5,
      WaffleBedspread6,
      WaffleBedspread7,
      WaffleBedspread8,
      WaffleBedspread9,
    ],
    url: "waffle-bedspreads",
    category: "waffle-weave",
    title: "Waffle Bedspreads",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
  {
    images: [
      WaffleBathrobe1,
      WaffleBathrobe2,
      WaffleBathrobe3,
      WaffleBathrobe4,
      WaffleBathrobe5,
      WaffleBathrobe6,
    ],
    url: "waffle-bathrobes",
    category: "waffle-weave",
    title: "Waffle Bathrobes",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
  {
    images: [
      WafflePeshtemal1,
      WafflePeshtemal2,
      WafflePeshtemal3,
      WafflePeshtemal4,
      WafflePeshtemal5,
      WafflePeshtemal6,
    ],
    url: "waffle-peshtemals",
    category: "waffle-weave",
    title: "Waffle Peshtemals",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
  {
    images: [
      DiamondThrow1,
      DiamondThrow2,
      DiamondThrow3,
      DiamondThrow4,
      DiamondThrow5,
    ],
    url: "diamond",
    category: "jacquard-throws",
    title: "Diamond",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
  {
    images: [
      StripedThrow1,
      StripedThrow2,
      StripedThrow3,
      StripedThrow4,
      StripedThrow5,
      StripedThrow6,
    ],
    url: "striped",
    category: "jacquard-throws",
    title: "Striped",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
  {
    images: [
      MuslinBedspread1,
      MuslinBedspread2,
      MuslinBedspread3,
      MuslinBedspread4,
      MuslinBedspread5,
      MuslinBedspread6,
      MuslinBedspread7,
      MuslinBedspread8,
    ],
    url: "muslin-bedspreads",
    category: "bedspreads",
    title: "Muslin Bedspreads",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
  {
    images: [
      MuslinThrow1,
      MuslinThrow2,
      MuslinThrow3,
      MuslinThrow4,
      MuslinThrow5,
      MuslinThrow6,
      MuslinThrow7,
    ],
    url: "muslin-throws",
    category: "bedspreads",
    title: "Muslin Throws",
    fabricType: "100% cotton",
    size: '37" x 71"',
    weight: "11.5-12.35 oz",
  },
];

export default products;
