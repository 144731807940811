import React, { useRef } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";

// Import your images

const Container = tw.div`relative overflow-hidden`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const Heading = tw.h2`text-4xl font-bold text-center mb-8`;

const SliderContainer = styled.div`
  position: relative;
`;

const Card = styled.div`
  ${tw`h-full flex! flex-col relative px-2`}
  width: 400px; /* Set the desired width of the cards */
  flex: 0 0 auto;
`;

const CardImageContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 86.76%; /* Aspect ratio (59 / 68) * 100 */
  overflow: hidden;
  border-radius: 8px; /* Optional: Add rounded corners */
`;

const CardImage = styled.div`
  ${tw`absolute top-0 left-0 w-full h-full bg-cover bg-center`}
  background-image: url("${(props) => props.imageSrc}");
  transition: opacity 0.3s ease;
  opacity: 1;
  &:hover {
    opacity: 0;
  }
`;

const SmallText = tw.div`mt-4 text-sm font-semibold text-gray-800 text-center`;

const ArrowButton = styled.button`
  ${tw`absolute top-1/2 transform -translate-y-1/2 p-2 rounded-full bg-gray-800 text-white`}
  z-index: 10;
  &:hover {
    background-color: #5a67d8;
  }
`;

const PrevButton = styled(ArrowButton)`
  ${tw`left-0 ml-4`}
`;

const NextButton = styled(ArrowButton)`
  ${tw`right-0 mr-4`}
`;

export default ({ heading = "Popular Designs", cards = [] }) => {
  const sliderRef = useRef(null);

  const sliderSettings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  return (
    <Container>
      <Content>
        <Heading>{heading}</Heading>
        <SliderContainer>
          <PrevButton onClick={handlePrev}>
            <ChevronLeftIcon />
          </PrevButton>
          <Slider ref={sliderRef} {...sliderSettings}>
            {cards.map((card, index) => (
              <Card key={index}>
                <a
                  href={card.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: "block", height: "100%" }}
                >
                  <CardImageContainer>
                    <CardImage imageSrc={card.secondImageSrc} />
                    <CardImage imageSrc={card.imageSrc} />
                  </CardImageContainer>
                  <SmallText>{card.smallText}</SmallText>
                </a>
              </Card>
            ))}
          </Slider>
          <NextButton onClick={handleNext}>
            <ChevronRightIcon />
          </NextButton>
        </SliderContainer>
      </Content>
    </Container>
  );
};
