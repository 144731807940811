import React from "react";
import tw from "twin.macro";
import { motion } from "framer-motion";
import useInView from "helpers/useInView";

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 overflow-hidden`;

function AnimationReveal({ disabled, children }) {
  if (disabled) {
    return <>{children}</>;
  }

  if (!Array.isArray(children)) children = [children];

  const childrenWithAnimation = children.map((child, i) => {
    return <AnimatedFadeInComponent key={i}>{child}</AnimatedFadeInComponent>;
  });
  return <>{childrenWithAnimation}</>;
}

function AnimatedFadeInComponent({ children }) {
  const [ref, inView] = useInView();

  return (
    <div ref={ref}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: inView ? 1 : 0,
        }}
        transition={{ duration: 1 }}
      >
        {children}
      </motion.div>
    </div>
  );
}

export default function AnimatedFadeIn(props) {
  return (
    <StyledDiv className="App">
      <AnimationReveal {...props} />
    </StyledDiv>
  );
}
