const ClassicPeshtemals = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/Classic Peshtemal Stone Washed 25.6.jpg");

const BlackStripe1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/black-stipe/1.jpg");

const Blueeye1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/blue-eye/(1).jpg");

const Cherry1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/cherry/(1).jpg");

const ClassicStriped1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/classic-striped/(1).jpg");

const GreenOlive1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/green-olive/(1).jpg");

const HotelBaja1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/hotel-baja/(1).jpg");

const Lailac1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/lailac/(1).jpg");

const Navy1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/navy/(1).jpg");

const Noir1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/noir/(1).jpg");

const Oriental1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/oriental/(1).jpg");

const SunsOut1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/suns-out/(3).jpg");

const Santorini1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/santorini/(1).jpg");

const Tiramisu1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/tiramisu/(1).jpg");

const Sand1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/sand/(1).jpg");

const SohoParadise1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/soho-paradise/(1).jpg");

const Pink1 = require("../images/catalog_item_imgs/peshtemal_towels/classic_peshtemals/pink/(1).jpg");

const JaquardPeshtemals11 = require("../images/catalog_item_imgs/peshtemal_towels/jacquard_peshtemals/bougainvillea/1.jpg");
const JaquardPeshtemals21 = require("../images/catalog_item_imgs/peshtemal_towels/jacquard_peshtemals/crab/1.jpg");
const JaquardPeshtemals31 = require("../images/catalog_item_imgs/peshtemal_towels/jacquard_peshtemals/diagonal/(1).jpg");
const JaquardPeshtemals41 = require("../images/catalog_item_imgs/peshtemal_towels/jacquard_peshtemals/moss/ (1).jpg");
const JaquardPeshtemals51 = require("../images/catalog_item_imgs/peshtemal_towels/jacquard_peshtemals/pink_ocean/(1).jpg");
const JaquardPeshtemals61 = require("../images/catalog_item_imgs/peshtemal_towels/jacquard_peshtemals/sakura/1.jpg");
const JaquardPeshtemals71 = require("../images/catalog_item_imgs/peshtemal_towels/jacquard_peshtemals/sun/1.jpg");
const JaquardPeshtemals81 = require("../images/catalog_item_imgs/peshtemal_towels/jacquard_peshtemals/tulip/ (1).jpg");

const PeshtemalBackpack11 = require("../images/catalog_item_imgs/peshtemal_backpacks/multi_color/(2).webp");

const PeshtemalBackpack21 = require("../images/catalog_item_imgs/peshtemal_backpacks/rainbow/1.png");
const PeshtemalBackpack31 = require("../images/catalog_item_imgs/peshtemal_backpacks/striped/(2).webp");

const MuslinPeshtemals11 = require("../images/catalog_item_imgs/peshtemal_towels/muslin_peshtemals/4_layer_muslin (1).jpg");

const JacquardPeshtemals = require("../images/catalog_item_imgs/peshtemal_towels/jacquard_peshtemals/bougainvillea/1.jpg");
const MuslinPeshtemals = require("../images/catalog_item_imgs/peshtemal_towels/muslin_peshtemals/muslin-generic.jpg");

const BathRobesImage = require("../images/catalog_item_imgs/bathrobes/generic.jpg");

const ClassicBathRobesImage = require("../images/catalog_item_imgs/bathrobes/classic_robes/classic_generic.jpg");

const MuslinBathRobesImage = require("../images/catalog_item_imgs/bathrobes/muslin_bathrobes/muslin_generic.jpg");
const FourLMuslinBathrobes3 = require("../images/catalog_item_imgs/bathrobes/muslin_bathrobes/four-layer-muslin-bathrobes/3.webp");
const MuslinBathrobes4 = require("../images/catalog_item_imgs/bathrobes/muslin_bathrobes/regular_muslin_bathrobes/4.jpg");

const PeshtemalBackpack = require("../images/catalog_item_imgs/peshtemal_backpacks/generic.webp");

const DiamondThrow1 = require("../images/catalog_item_imgs/bedspreads_throws/Jacq_Bed_Throws/diamond/1.webp");
const StripedThrow1 = require("../images/catalog_item_imgs/bedspreads_throws/Jacq_Bed_Throws/striped/1 (1).jpg");

const WaffleWeave = require("../images/catalog_item_imgs/waffle/generic.jpg");
const WaffleBathrobe = require("../images/catalog_item_imgs/waffle/waffle_bathrobes/generic.jpg");
const WaffleBedspread = require("../images/catalog_item_imgs/waffle/waffle_bedspreads/generic.jpg");
const WaffleHandTowel = require("../images/catalog_item_imgs/waffle/waffle_hand_towels/generic.jpg");
const WafflePeshtemal = require("../images/catalog_item_imgs/waffle/waffle_peshtemal/generic.jpg");

const BedSpread = require("../images/catalog_item_imgs/bedspreads_throws/generic.webp");
const BedSpreadJaquard = require("../images/catalog_item_imgs/bedspreads_throws/Jacq_Bed_Throws/generic.jpg");
const BedSpreadMuslin = require("../images/catalog_item_imgs/bedspreads_throws/Muslin_Bed_Spreads/generic.webp");
const ThrowMuslin = require("../images/catalog_item_imgs/bedspreads_throws/Muslin_Throws/muslin_bed_throw_generic.webp");

const BeachTowelsImage = require("../images/catalog_item_imgs/peshtemal_towels/beach_towel_generic.jpg");

const categories = [
  {
    category: "beach-towels",
    imageSrc: BeachTowelsImage,
    title: "Beach Towels",
    url: "/catalog/beach-towels",
    categorySw: "Y",
    items: [
      {
        imageSrc: ClassicPeshtemals,
        url: "/catalog/classic-peshtemals",
        title: "Classic Peshtemals",
      },
      {
        imageSrc: JacquardPeshtemals,
        url: "/catalog/jaquard-peshtemals",
        title: "Jaquard Peshtemals",
      },
      {
        imageSrc: MuslinPeshtemals,
        url: "/catalog/muslin-peshtemals",
        title: "Muslin Peshtemals",
      },
    ],
  },

  {
    category: "bathrobes",
    imageSrc: BathRobesImage,
    title: "Bathrobes",
    url: "/catalog/bathrobes",
    categorySw: "Y",
    items: [
      {
        imageSrc: ClassicBathRobesImage,
        url: "/catalog/bathrobes/classic-bathrobes",
        title: "Classic Bathrobes",
      },
      {
        imageSrc: MuslinBathRobesImage,
        url: "/catalog/muslin-bathrobes",
        title: "Muslin Bathrobes",
      },
    ],
  },
  {
    category: "peshtemal-backpacks",
    imageSrc: PeshtemalBackpack,
    title: "Peshtemal Backpacks",
    url: "/catalog/peshtemal-backpacks",
    categorySw: "N",
    items: [
      {
        imageSrc: PeshtemalBackpack11,
        url: "/catalog/peshtemal-backpacks/multi-color",
        title: "Multi-Color",
      },
      {
        imageSrc: PeshtemalBackpack21,
        url: "/catalog/peshtemal-backpacks/rainbow",
        title: "Rainbow",
      },
      {
        imageSrc: PeshtemalBackpack31,
        url: "/catalog/peshtemal-backpacks/striped",
        title: "Striped",
      },
    ],
  },
  {
    category: "bedspreads",
    imageSrc: BedSpread,
    title: "Bedspreads",
    url: "/catalog/bedspreads",
    categorySw: "Y",
    items: [
      {
        imageSrc: BedSpreadJaquard,
        url: "/catalog/jacquard-throws",
        title: "Jaquard Throws",
      },
      {
        imageSrc: ThrowMuslin,
        url: "/catalog/bedspreads/muslin-throws",
        title: "Muslin Throws",
      },
      {
        imageSrc: BedSpreadMuslin,
        url: "/catalog/bedspreads/muslin-bedspreads",
        title: "Muslin Bedspreads",
      },
    ],
  },
  {
    category: "waffle-weave",
    imageSrc: WaffleWeave,
    title: "Waffle Weave",
    url: "/catalog/waffle-weave",
    categorySw: "N",
    items: [
      {
        imageSrc: WaffleBathrobe,
        url: "/catalog/waffle-weave/waffle-bathrobes",
        title: "Waffle Bathrobes",
      },
      {
        imageSrc: WaffleBedspread,
        url: "/catalog/waffle-weave/waffle-bedspreads",
        title: "Waffle Bedspreads",
      },
      {
        imageSrc: WaffleHandTowel,
        url: "/catalog/waffle-weave/waffle-hand-towels",
        title: "Waffle Hand Towels",
      },
      {
        imageSrc: WafflePeshtemal,
        url: "/catalog/waffle-weave/waffle-peshtemals",
        title: "Waffle Peshtemal",
      },
    ],
  },

  {
    category: "classic-peshtemals",
    imageSrc: ClassicPeshtemals,
    title: "Classic Peshtemals",
    url: "/catalog/classic-peshtemals",
    categorySw: "N",
    items: [
      {
        imageSrc: BlackStripe1,
        url: "/catalog/classic-peshtemals/black-stripe",
        title: "Black Stripe",
      },
      {
        imageSrc: Oriental1,
        url: "/catalog/classic-peshtemals/oriental",
        title: "Oriental",
      },
      {
        imageSrc: HotelBaja1,
        url: "/catalog/classic-peshtemals/hotel-baja",
        title: "Hotel Baja",
      },
      {
        imageSrc: ClassicStriped1,
        url: "/catalog/classic-peshtemals/classic-striped",
        title: "Classic Striped",
      },
      {
        imageSrc: Navy1,
        url: "/catalog/classic-peshtemals/navy",
        title: "Navy",
      },
      {
        imageSrc: SunsOut1,
        url: "/catalog/classic-peshtemals/suns-out",
        title: "Suns Out",
      },
      {
        imageSrc: Santorini1,
        url: "/catalog/classic-peshtemals/santorini",
        title: "Santorini",
      },
      {
        imageSrc: Tiramisu1,
        url: "/catalog/classic-peshtemals/tiramisu",
        title: "Tiramisu",
      },
      {
        imageSrc: Lailac1,
        url: "/catalog/classic-peshtemals/lailac",
        title: "Lailac",
      },
      {
        imageSrc: Blueeye1,
        url: "/catalog/classic-peshtemals/blue-eye",
        title: "Blue Eye",
      },
      {
        imageSrc: Noir1,
        url: "/catalog/classic-peshtemals/noir",
        title: "Noir",
      },
      {
        imageSrc: GreenOlive1,
        url: "/catalog/classic-peshtemals/green-olive",
        title: "Green Olive",
      },
      {
        imageSrc: Sand1,
        url: "/catalog/classic-peshtemals/sand",
        title: "Sand",
      },
      {
        imageSrc: Cherry1,
        url: "/catalog/classic-peshtemals/cherry",
        title: "Cherry",
      },
      {
        imageSrc: SohoParadise1,
        url: "/catalog/classic-peshtemals/soho-paradise",
        title: "Soho Paradise",
      },
      {
        imageSrc: Pink1,
        url: "/catalog/classic-peshtemals/pink",
        title: "Pink",
      },
    ],
  },
  {
    category: "muslin-peshtemals",
    title: "Muslin Peshtemals",
    url: "/catalog/muslin-peshtemals",
    categorySw: "N",
    items: [
      {
        imageSrc: MuslinPeshtemals11,
        url: "/catalog/muslin-peshtemals/four-layer-muslin",
        title: "4 Layer Muslin Peshtemal",
      },
    ],
  },
  {
    category: "jacquard-throws",
    title: "Jacquard Throws",
    url: "/catalog/jacquard-throws",
    categorySw: "N",
    items: [
      {
        imageSrc: DiamondThrow1,
        url: "/catalog/jacquard-throws/diamond",
        title: "Diamond",
      },
      {
        imageSrc: StripedThrow1,
        url: "/catalog/jacquard-throws/striped",
        title: "Striped",
      },
    ],
  },
  {
    category: "muslin-bathrobes",
    title: "Muslin Bathrobes",
    url: "/catalog/muslin-bathrobes",
    categorySw: "N",
    items: [
      {
        imageSrc: MuslinBathrobes4,
        url: "/catalog/muslin-bathrobes/regular-muslin-robes",
        title: "Regular Muslin Bathrobes",
      },
      {
        imageSrc: FourLMuslinBathrobes3,
        url: "/catalog/muslin-bathrobes/four-layer-muslin-robes",
        title: "4 Layer Muslin Bathrobes",
      },
    ],
  },
  {
    category: "jaquard-peshtemals",
    title: "Jaquard Peshtemals",
    url: "/catalog/jaquard-peshtemals",
    categorySw: "N",
    items: [
      {
        imageSrc: JaquardPeshtemals11,
        url: "/catalog/jaquard-peshtemals/bougainvillea",
        title: "Bougainvillea",
      },
      {
        imageSrc: JaquardPeshtemals21,
        url: "/catalog/jaquard-peshtemals/crab",
        title: "Crab",
      },
      {
        imageSrc: JaquardPeshtemals31,
        url: "/catalog/jaquard-peshtemals/diagonal",
        title: "Diagonal",
      },
      {
        imageSrc: JaquardPeshtemals41,
        url: "/catalog/jaquard-peshtemals/moss",
        title: "Moss",
      },
      {
        imageSrc: JaquardPeshtemals51,
        url: "/catalog/jaquard-peshtemals/pink-ocean",
        title: "Pink Ocean",
      },
      {
        imageSrc: JaquardPeshtemals61,
        url: "/catalog/jaquard-peshtemals/sakura",
        title: "Sakura",
      },
      {
        imageSrc: JaquardPeshtemals71,
        url: "/catalog/jaquard-peshtemals/sun",
        title: "Sun",
      },
      {
        imageSrc: JaquardPeshtemals81,
        url: "/catalog/jaquard-peshtemals/tulip",
        title: "Tulip",
      },
    ],
  },
];

export default categories;
