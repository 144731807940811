import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import Slider from "react-slick";
import { SectionHeading, Subheading } from "components/misc/Headings.js";
import { PrimaryLink as PrimaryLinkBase } from "components/misc/Links.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CarouselImage1 = require("../../images/home_page/(1).jpg");
const CarouselImage2 = require("../../images/home_page/(2).jpg");
const CarouselImage3 = require("../../images/home_page/(3).jpg");
const CarouselImage4 = require("../../images/home_page/(4).jpg");
const CarouselImage5 = require("../../images/home_page/(5).jpg");
const CarouselImage6 = require("../../images/home_page/(6).jpg");

const Container = tw.div`relative`;
const SubContent = tw.div`max-w-screen-xl mx-auto py-10 lg:py-12 text-center`;

const ThreeColumn = tw.div`flex flex-wrap`;
const Column = tw.div`xl:mr-12 xl:last:mr-0`;
const HeadingColumn = styled(Column)((props) => [
  tw`w-full xl:w-5/12`,
  props.textOnLeft ? tw`xl:order-first` : tw`xl:order-last xl:ml-12 xl:mr-0`,
]);
const HeadingInfoContainer = tw.div`text-center xl:text-left max-w-lg xl:max-w-none mx-auto xl:mx-0`;
const HeadingTitle = tw(SectionHeading)`mt-4 xl:text-left leading-tight`;
const HeadingDescription = tw.p`text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-8`;
const PrimaryLink = styled(PrimaryLinkBase)`
  ${tw`inline-flex justify-center xl:justify-start items-center mt-8 text-lg`}
  svg {
    ${tw`ml-2 w-5 h-5`}
  }
`;

const CarouselContainer = tw.div`mx-auto w-full py-10`;
const Slide = tw.div`relative w-full h-144 flex justify-center items-center`;

const ImageLink = styled.a`
  ${tw`relative w-full h-full block`}
  &:hover .image-overlay {
    opacity: 0.5; /* Change this value to control the opacity on hover */
  }
`;

const Image = styled.img`
  ${tw`w-full h-full object-cover`}
`;

const Overlay = styled.div`
  ${tw`absolute inset-0 bg-black opacity-0 transition-opacity duration-300`}
`;

const Caption = styled.div`
  ${tw`absolute inset-0 flex flex-col justify-center items-center bottom-0 left-0 text-left text-white p-4 bg-opacity-25 px-2 rounded-lg opacity-0 transition-opacity duration-300`}
  ${ImageLink}:hover & {
    opacity: 1; /* Change this value to control the opacity on hover */
  }
`;

const Carousel = ({
  id = "carousel",
  subheading = "About Us",
  headingHtmlComponent = (
    <>
      Wave <span tw="text-primary-500">&</span> Shine
    </>
  ),
  description = "Bring the serene beauty of the coast into your home with Wave and Shine's luxurious bath and bedding textiles. Our beach towels and linens transform every moment into a breezy, seaside escape.",
  linkText = "Contact Us",
  cardLinkText = "Check Out Collections",
  textOnLeft = false,
}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const cards = [
    {
      imageSrc: CarouselImage1,
      link: "/catalog",
    },
    {
      imageSrc: CarouselImage2,
      link: "/catalog",
    },
    {
      imageSrc: CarouselImage3,
      link: "/catalog",
    },
    {
      imageSrc: CarouselImage4,
      link: "/catalog",
    },
    {
      imageSrc: CarouselImage5,
      link: "/catalog",
    },
    {
      imageSrc: CarouselImage6,
      link: "/catalog",
    },
  ];

  return (
    <Container id={id}>
      <CarouselContainer>
        <Slider {...settings}>
          {cards.map((card, index) => (
            <Slide key={index}>
              <ImageLink href={card.link}>
                <Image src={card.imageSrc} alt={`Slide ${index + 1}`} />
                <Overlay className="image-overlay" />
                <Caption>{cardLinkText}</Caption>
              </ImageLink>
            </Slide>
          ))}
        </Slider>
      </CarouselContainer>
      {/* <SubContent>
        <ThreeColumn>
          <HeadingColumn textOnLeft={textOnLeft}>
            <HeadingInfoContainer>
              <Subheading>{subheading}</Subheading>
              <HeadingTitle>{headingHtmlComponent}</HeadingTitle>
              <HeadingDescription>{description}</HeadingDescription>
              <PrimaryLink href="/contact">
                {linkText} <ArrowRightIcon />
              </PrimaryLink>
            </HeadingInfoContainer>
          </HeadingColumn>
        </ThreeColumn>
      </SubContent> */}
    </Container>
  );
};

export default Carousel;
